<template>
  <fields :result="result">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      addButtTitle="Add Subscribers"
      @line-clicked="checkClicked(...arguments)"
    ></lineFieldsArray>
    <input type="hidden" :value="JSON.stringify(pairArray)" name="multiObject" />
  </fields>
</template>
<script>
import lineButtons from "@/cfg/lineButtons.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import fields from "@/commonComponents/dynamic/fields.vue";
export default {
  components: { lineFieldsArray, fields },
  data() {
    return { pairArray: [] };
  },
  props: ["result"],
  computed: {
    fields() {
      let fields = {
        copy: {
          input: false,
          addingInput: false,
          inputFieldFormatter: "copyStep",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-2",
          },
        },
        remove: lineButtons.remove,
        ...this.result.fields,
      };
      return fields;
    },
  },
  methods: {
    checkClicked(index, name) {
      console.log(index, name);
      if (name == "copyStep") {
        this.pairArray.splice(index,0,this.pairArray[index])
      }
    },
    checkInput(value) {
      this.pairArray = value;
      // console.log(value);
      // let sentValue = {};
      // let update = true;
      // let caller = this;
      // value.forEach(function (info) {
      //   if (typeof sentValue[info.index] != "undefined") {
      //     update = false;
      //     return;
      //   }
      //   caller.$set(sentValue, info.index, info.value);
      // });
      // if (update) {
      //   this.$emit("input", sentValue);
      // }
    },
  },
};
</script>